.status-node input {
  background: transparent;
  background-color: transparent;
}

/* Disable Safari's auto-fill styles */
input:-webkit-autofill {
  visibility: hidden;
  display: none;
}

.no-mouse-interaction {
  pointer-events: none;
}

@keyframes greenFlash {
  0% { background-color: transparent; }
  50% { background-color: rgba(34, 197, 94, 0.2); }
  100% { background-color: transparent; }
}

.step-item.completed {
  animation: greenFlash 0.5s ease-out forwards;
}

.ime-disabled input {
  ime-mode: disabled !important;
}